import React, { Component } from 'react';
import query from './utils/query';
import './App.css';
import { validationToken } from './config/default';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: [],
    };
    this.getDeals = this.getDeals.bind(this);
    this.successCallback = this.successCallback.bind(this);
  }
  componentDidMount() {
    this.getDeals();
    setInterval(this.getDeals, 15000);
  }

  async getDeals() {
    query('POST', '/v1/reservations/all', {
      validationToken,
    }).then((json) => {
      if (json.success) {
        this.setState({ reservations: json.reservations });
      }
    });
  }

  successCallback() {
    this.getDeals();
  }

  render() {
    const { reservations } = this.state;
    return (<div className="App">
      <header className="App-header">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <h2>
          Reservation List
        </h2>
        <table border="1" style={{ width: "100%", fontFamily: "Arial", fontSize:"12px" }}>
          <tr>
          <td>
              Reserved For
            </td>
          <td>
              Reservation Code
            </td>
            <td>
              Restaurant Name
            </td>
            <td>
              Contact Person
            </td>
            <td>
              Phone
            </td>
            <td>
              Client
            </td>
            <td>
              Client Phone
            </td>
            <td>
              Deal Name
            </td>
            <td>
              Type
            </td>
            
           
            <td>
              Status
            </td>
          </tr>
          {
            reservations.map((item, index) => {
              return (
                <tr>
                   <td>
                    {item.reservations}
                  </td>
                 <td>
                    {item.reservationCode}
                  </td>
                  <td>
                    {item.restaurantName}
                  </td>
                 
                  <td>
                    {item.contactPersonName}
                  </td>
                  <td>
                    {item.contactPersonPhone}
                  </td>
                  <td>
                    {item.clientName}
                  </td>
                  <td>
                    {item.clientPhone}
                  </td>
                  <td>
                    {item.dealName}
                  </td>
                  <td>
                    {item.dealType}
                  </td>
                  
                 
                  <td>
                    {item.status}
                  </td>
                </tr>
              )
            })
          }

        </table>
      </header>
    </div>)
  }
}

export default App;
